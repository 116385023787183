const breakpoints = {
  sm: "640px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
};
import btnBg1 from "assets/images/btn-bg-1.png";
import btnBg2 from "assets/images/btn-bg-2.png";
import HIcon from "assets/images/heading-icon.svg";
import HIcon2 from "assets/images/heading-icon2.svg";

export default {
  breakpoints,
  fonts: {
    body: "bombing",
    heading: "bombing",
  },
  colors: {
    primary: {
      50: "#fed01b",
      100: "#fed01b",
      200: "#fed01b",
      300: "#fed534",
      400: "#fed01b",
      500: "#F9DA0A",
      600: "#F0D100",
      700: "#F0D100",
      800: "#F0D100",
      900: "#F0D100",
    },
    text: "#000000",
    background: "#F9DA0A",
    error: "#E84A4A",
    secondaryText: "#ffffff",
  },
  components: {
    List: {
      baseStyle: {
        item: {
          fontWeight: 500,
          fontFamily: `'Inter', sans-serif`,
          fontSize: "14px",
          color: "white",
        },
      },
      variants: {
        primary: {
          item: {
            fontWeight: 600,
            color: "black",
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: "text",
        fontSize: "18px",
      },
      variants: {
        primary: {
          color: "secondaryText",
        },
        gotham: {
          fontFamily: `'Inter', sans-serif`,
          fontSize: "16px",
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: "500",
        fontSize: "16px",
        fontFamily: `'Inter', sans-serif`,
      },
      variants: {
        outline: {
          bg: `url(${btnBg2}) no-repeat`,
          bgSize: "100% 100%",
          border: "none",
          color: "black",
          minW: "160px",
          h: "50px",
          _hover: {
            bg: `url(${btnBg2}) no-repeat`,
            bgSize: "100% 100%",
          },
        },
        solid: {
          bg: `url(${btnBg1}) no-repeat`,
          bgSize: "100% 100%",
          color: "black",
          minW: "160px",
          h: "50px",
          _hover: {
            bg: `url(${btnBg1}) no-repeat`,
            bgSize: "100% 100%",
          },
        },
        ghost: {
          _hover: {
            bg: "transparent",
          },
        },
        unstyled: {
          background: "rgba(250, 250, 250, 0.2)",
          borderRadius: "6px",
          color: "white",
          fontSize: "16px",
          fontWeight: "300",
          h: "30px",
          p: "2px 14px",
        },
        menuBtn: {
          bg: " rgba(242, 242, 242, 0.2)",
          h: "auto",
          minW: "auto",
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily: "bombing",
      },
      variants: {
        primary: {
          fontSize: "49px",
          _before: {
            content: `url(${HIcon})`,
            display: "inline-block",
            mr: "20px",
          },
        },
        secondary: {
          fontSize: "49px",
          _before: {
            content: `url(${HIcon2})`,
            display: "inline-block",
            mr: "20px",
          },
        },
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: "background",
          borderColor: "gray",
        },
        item: {
          bg: "background",
        },
      },
    },
  },
  styles: {
    global: {
      body: {
        fontFamily: "bombing",
        bg: "background",
        color: "text",
        fontWeight: "500",
      },
    },
  },
};

import React from 'react'
import { Box, Text, Image, Flex } from '@chakra-ui/react'
import Logo from 'assets/images/logo2.svg'
import { menu } from 'utils/const'
import BGImage from 'assets/images/lines-bg.png'
import CoinPlatforms from 'component/coinPlatforms'

export default function Footer() {
    const intoView = (id: string) => {
        const elem = document.getElementById(id)
        elem?.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <Box
            as="footer"
            textAlign="center"
            pt="150px"
            bg={{ md: `url(${BGImage}) no-repeat center top` }}
            bgSize={{ md: 'cover' }}
        >
            <Image src={Logo} maxW="140px" alt="Logo" mx="auto" />
            <Text my="30px" maxW="660px" mx="auto" variant="gotham">
                $ESCO Coin has no association with Dicky or his creation
                Escobart. This Coin is simply paying homage to a meme we all
                love and recognize.
            </Text>
            <Flex
                as="nav"
                maxW="600px"
                justify="center"
                mx="auto"
                gap="2"
                align="center"
                display={{ base: 'none', md: 'flex' }}
            >
                {menu.map(({ label, path }) => (
                    <Text
                        onClick={() => intoView(path)}
                        as="a"
                        href={path}
                        key={label}
                        mr="16px"
                        variant="gotham"
                    >
                        {label}
                    </Text>
                ))}
            </Flex>
            <CoinPlatforms />

            <Text
                variant="gotham"
                color="white"
                fontSize="14px"
                bg="black"
                py="25px"
                mt="100px"
            >
                © 2023 by{' '}
                <Text as="span" color="primary.500">
                    ESCO Coin.
                </Text>{' '}
                All rights reserved.
            </Text>
        </Box>
    )
}

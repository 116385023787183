import React from 'react'
import {
    BirdIcon,
    TelegramIcon,
    Twitter,
    UniSwapIcon,
    FinTechIcon,
    CoinsMarketIcon,
    DexToolIcon,
} from 'assets/icons'
import { Center } from '@chakra-ui/react'
export default function CoinPlatforms() {
    return (
        <Center
            mt="50px"
            justifyContent="center"
            gap="40px"
            mx="auto"
            flexWrap="wrap"
        >
            <a
                href="https://t.me/escocoinofficial"
                target="_blank"
                rel="noreferrer"
            >
                <TelegramIcon />
            </a>
            <a
                href="https://twitter.com/escocoin"
                target="_blank"
                rel="noreferrer"
            >
                <Twitter />
            </a>
            <a href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7163436b8efffb469f6bb81cc908b1661d4795e6" target="_blank" rel="noreferrer">
                <UniSwapIcon />
            </a>
            <a href="https://etherscan.io/token/0x7163436b8EfFfB469F6BB81cc908b1661d4795e6" target="_blank" rel="noreferrer">
                <FinTechIcon />
            </a>
            <a href="https://www.coingecko.com/en/coins/esco-coin" target="_blank" rel="noreferrer">
                <BirdIcon />
            </a>
            <a href="https://coinmarketcap.com/currencies/esco-coin" target="_blank" rel="noreferrer">
                <CoinsMarketIcon />
            </a>
            <a href="https://www.dextools.io/app/en/ether/pair-explorer/0xd04aa6c33e03160381f306fe5386ffd3291e7ca4" target="_blank" rel="noreferrer">
                <DexToolIcon />
            </a>
        </Center>
    )
}

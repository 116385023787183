import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@chakra-ui/react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useDisconnect, useSwitchNetwork, useNetwork } from "wagmi";
import { sepolia } from "viem/chains";

export default function ConnectButton() {
  const { chain } = useNetwork();
  const { isLoading, switchNetwork } = useSwitchNetwork();
  const [loading, setLoading] = useState(false);
  const [isSwitch, setSwitch] = useState(false);
  const { open, setDefaultChain } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();
  const label = isConnected
    ? address?.replaceAll(address.slice(5, 38), "****")
    : "Connect Wallet";

  async function onOpen() {
    setLoading(true);
    setDefaultChain(sepolia);
    await open();
    switchNetwork?.(sepolia?.id);
    setLoading(false);
  }

  if (isConnected && chain?.id !== sepolia?.id && !isSwitch) {
    setTimeout(() => {
      setSwitch(true);
      switchNetwork?.(sepolia?.id);
    }, 7000);
  }

  function onClick() {
    if (isConnected) {
      disconnect();
    } else {
      onOpen();
    }
  }

  return (
    <>
      <Button
        isLoading={loading || isLoading}
        onClick={onClick}
        ml={{ base: "auto", lg: "0" }}
      >
        {label}
      </Button>
    </>
  );
}

import { UseToastOptions, createStandaloneToast } from "@chakra-ui/react";
import { formatUnits } from "viem";

export const { toast } = createStandaloneToast();

export const showToast = (
  type: UseToastOptions["status"],
  title: string,
  description?: string
) => {
  toast({
    status: type,
    title: title,
    description: description,
    position: "top",
    duration: 2000,
    isClosable: true,
  });
};

export const countdownTimer = () => {
  let x = "";
  setInterval(() => {
    const countDownDate = new Date("May 26, 2023 16:37:52").getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;

    const days = Math.floor(distance / day);
    const hours = Math.floor((distance % day) / hour);
    const minutes = Math.floor((distance % hour) / minute);
    const seconds = Math.floor((distance % minute) / second);
    console.log("time", `${days} days, ${hours}:${minutes}:${seconds}`);

    if (distance < 0) {
      clearInterval(countdownTimer());
      return false;
    }

    return (x = `${days} days, ${hours}:${minutes}:${seconds} `);
  }, 1000);
  console.log("time x", x);
  return x;
};

function countZerosAfterDecimal(number: string) {
  const decimalPart = number.split(".")[1];

  let zerosCount = 0;
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] === "0") {
      zerosCount++;
    } else {
      break;
    }
  }

  return zerosCount;
}

export const numberFormate = (val: string) => {
  const number = parseFloat(formatUnits(BigInt(val), 18))?.toFixed(12);
  const decimalcount = countZerosAfterDecimal(number);
  const decimals = number?.split(".")[1]?.slice(decimalcount);
  // console.log(number, decimalcount, decimals)
  return decimalcount > 4
    ? `${number?.split(".")[0]}.0<sub>${
        decimalcount - 1
      }</sub>${decimals?.slice(0, 3)}`
    : parseFloat(formatUnits(BigInt(val), 18))?.toFixed(5);
};
